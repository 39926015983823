const styles = {
  mainPost: {
    width: ["100%", "100%", "65%"],
  },
  sidePost: {
    width: ["100%", "100%", "35%"],
  }
};

export default styles;
