import { Box } from "rebass/styled-components";
import styles from "../../styles/popUpStyle";

interface Props {
  show: boolean;
  children: JSX.Element;
}

function PopUp(props: Props) {
  const { show, children } = props;
  const popUpVisible = show ? "visible" : "hidden";
  const positionOpacity = show ? 1 : 0;

  return (
    <Box
      sx={{
        ...styles.container,
        visibility: popUpVisible,
        opacity: positionOpacity,
      }}
    >
      <Box sx={{ ...styles.position, opacity: positionOpacity }}>
        {children}
      </Box>
    </Box>
  );
}

export default PopUp;
