import { Flex, Box, Text } from "rebass/styled-components";
import Icon from "../common/Icon";
import styles from "../../styles/paginationStyle";
import CustomLink from "../common/CustomLink";

interface Props {
  basePath: string;
  afterPath?: string;
  pageSelected: number;
  totalPosts: number;
  maxNumPages: number;
}

function Pagination(props: Props) {
  const { basePath, afterPath, pageSelected, totalPosts, maxNumPages } = props;

  // Logic
  const numPages = Math.ceil(totalPosts / 10); // Total number of pages (there are 10 posts per page)
  const numPagesToShow = Math.ceil(
    numPages > maxNumPages ? maxNumPages : numPages
  ); // Total number shown on the screen
  const offset =
    pageSelected > numPagesToShow ? pageSelected - numPagesToShow + 1 : 1; // Which numbers appear on screen

  // Styles
  const prevArrowStyle =
    pageSelected === 1 ? styles.prevButtonDisabled : styles.prevButton;
  const nextArrowStyle =
    pageSelected === numPagesToShow
      ? styles.nextButtonDisabled
      : styles.nextButton;

  const buildHref = (pageToGo: number) => {
    const afterValue = afterPath ? `/${afterPath}` : "";
    let href = "";
    if ((pageToGo === 1 || pageToGo === 0) && afterValue === "") {
      href = `${basePath}`;
    } else if (pageToGo > numPages) {
      href = `${basePath}page/${pageSelected}${afterValue}`;
    } else {
      href = `${basePath}page/${pageToGo}${afterValue}`;
    }
    return href;
  };

  const renderNumbers = () => {
    return [...Array(numPagesToShow)].map((_, i) => {
      const numToShow = i + offset;
      const style =
        numToShow === pageSelected
          ? { ...styles.numberBox, ...styles.numberBoxClicked }
          : styles.numberBox;

      return (
        <CustomLink key={i} href={buildHref(numToShow)}>
          <Flex width="40px" height="40px" sx={style}>
            <Text as="p">{numToShow}</Text>
          </Flex>
        </CustomLink>
      );
    });
  };

  return (
    <Box sx={{ width: "100%", my: "32px" }}>
      <Flex width="378px" height="40px" sx={styles.container}>
        <CustomLink
          href={buildHref(pageSelected - 1)}
          disabled={pageSelected === 1}
        >
          <Flex width="40px" height="40px" sx={prevArrowStyle}>
            <Icon name="prev" color={"labradorBlack"} fontSize={2} />
          </Flex>
        </CustomLink>

        {renderNumbers()}

        <CustomLink
          href={buildHref(pageSelected + 1)}
          disabled={pageSelected === numPages}
        >
          <Flex width="40px" height="40px" sx={nextArrowStyle}>
            <Icon name="next" color={"labradorBlack"} fontSize={2} />
          </Flex>
        </CustomLink>
      </Flex>
    </Box>
  );
}

export default Pagination;
