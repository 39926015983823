const keyframes = {
  "@keyframes fadeInOut": {
    "0%": { opacity: 0 },
    "50%": { opacity: 1 },
    "100%": { opacity: 0 },
  },
};
const styles = {
  container: {
    visibility: "hidden",
    opacity: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    height: "100%",
    width: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999,
  },
  position: {
    position: "absolute",
    overflow: "auto",
    bg: "background",
    alignItems: "center",
    top: ["43%", "43%", "50%"],
    left: ["20%", "20%", "50%"],
    transform: [
      "translate(-11%, -40%) !important",
      "translate(-11%, -40%) !important",
      "translate(-50%, -50%) !important",
    ],
    opacity: 0,
    transition: "opacity 1s ease-in-out",
  }
};

export default { ...keyframes, ...styles };
