import { Flex } from "rebass/styled-components";
import theme from "../../styles/theme";

function SecondaryPosts({ children }: { children: JSX.Element[] }) {
  const align = children.length < 3 ? "flex-start" : "space-between";
  return (
    <Flex
      sx={{
        ...theme.colToRow,
        gap: "32px",
        justifyContent: align,
        flexWrap: "wrap",
      }}
    >
      {children}
    </Flex>
  );
}

export default SecondaryPosts;
