const styles = {
  title: {
    fontSize: "32px",
    lineHeigh: "32px",
    fontWeight: 500,
  },
  imgBox: {
    width: ["initial", "initial", "45%"],
    height: ["300px", "300px", "370px"],
    m: ["16px", "16px", 0],
    position: "relative",
  },
  infoFlex: {
    py: ["24px", "24px", "40px"],
    px: ["16px", "16px", "24px"],
  },
  closeIcon: {
    justifyContent: "right",
    mt: "16px",
    mr: "16px",
  },
};

export default styles;
