import { Flex, Text, Box } from "rebass/styled-components";
import FeaturedImage from "./common/FeaturedImage";
import { CategoryType, InferredPost, PostType } from "../types";
import styles from "../styles/postStyle";
import theme from "../styles/theme";
import useTranslation from "next-translate/useTranslation";
import CategoriesBlock from "./sections/CategoriesBlock";
import ReactHtmlParser from "react-html-parser";
import CustomLink from "./common/CustomLink";

interface Props {
  postData: InferredPost;
  postType?: PostType;
  numCategories?: number;
}

function Post(props: Props) {
  const { t } = useTranslation("common");
  const { postData, postType = "mainPost", numCategories } = props;

  const featImgUrl = postData?.featuredImage?.node?.sourceUrl;
  const categories: CategoryType[] = postData?.categories?.nodes || [];
  const catToShow: CategoryType[] = numCategories
    ? categories.slice(0, numCategories)
    : categories;

  const mainFlexDirection =
    postType === "sidePost"
      ? ["column", "column", "column", "row"]
      : ["column"];

  const imgBoxWidth =
    postType === "sidePost" ? ["100%", "100%", "100%", "40%"] : "100%";
  const aspectRatio = postType === "sidePost" ? 1 : 4 / 3;

  const containerWidth = () => {
    switch (postType) {
      case "scndPost":
        return ["100%", "100%", "30%"];
      case "scndPostBlock":
        return ["100%", "100%", "45%"];
      default:
        return [];
    }
  };

  return (
    <Flex
      sx={{
        flexDirection: mainFlexDirection,
        gap: "12px",
        width: containerWidth,
      }}
    >
      <Box width={imgBoxWidth}>
        <CustomLink href={`${postData?.uri}`} prefetch>
          <FeaturedImage
            url={featImgUrl || ""}
            width="100%"
            aspectRatio={`${aspectRatio}`}
            shouldLazyLoad={postType === "mainPost" ? false : true}
            priority={postType === "mainPost" ? true : false}
          />
        </CustomLink>
      </Box>

      <CustomLink href={`${postData?.uri}`} prefetch>
        <Flex sx={{ flexDirection: "column", gap: "12px", mb: 0 }}>
          <Flex sx={{ flexDirection: "column", gap: "8px" }}>
            <Text as="h3" sx={theme.title}>
              {postData?.title}
            </Text>

            {!!postData?.excerpt && (
              <>
                {postType !== "sidePost" ? (
                  <Text as="p" sx={theme.description} mt="-16px">
                    {ReactHtmlParser(postData?.excerpt || "")}
                  </Text>
                ) : (
                  <Text
                    as="p"
                    sx={{ ...theme.description, ...styles.sidePostDesc }}
                  >
                    {ReactHtmlParser(postData?.excerpt || "")}
                  </Text>
                )}
              </>
            )}
          </Flex>

          <Text
            as="h4"
            sx={{
              ...styles.readMore,
              mt: !!postData?.excerpt && postType !== "sidePost" ? "-20px" : 0,
            }}
          >
            {t("readMore")}
          </Text>
        </Flex>
      </CustomLink>
    </Flex>
  );
}

export default Post;
