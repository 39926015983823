const hoverStyle = {
  "&:hover": {
    bg: "springGreen",
    color: "samoyedWhite",
    opacity: 0.5,
  },
};

const prevButton = {
  border: "1px solid lightGray",
  borderRadius: "20px 0 0 20px",
  justifyContent: "center",
  alignItems: "center",
  ...hoverStyle,
};

const nextButton = {
  borderTop: "1px solid lightGray",
  borderRight: "1px solid lightGray",
  borderBottom: "1px solid lightGray",
  borderRadius: "0 20px 20px 0",
  justifyContent: "center",
  alignItems: "center",
  ...hoverStyle,
};

const styles = {
  container: {
    flexDirection: "row",
    margin: "auto",
    justifyContent: "center",
  },
  prevButton: {
    ...prevButton,
  },
  prevButtonDisabled: {
    ...prevButton,
    "&:hover": {},
  },
  numberBox: {
    borderTop: "1px solid lightGray",
    borderRight: "1px solid lightGray",
    borderBottom: "1px solid lightGray",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 400,
    ...hoverStyle,
  },
  numberBoxClicked: {
    bg: "springGreen",
    color: "samoyedWhite",
  },
  nextButton: {
    ...nextButton,
  },
  nextButtonDisabled: {
    ...nextButton,
    "&:hover": {},
  },
};

export default styles;
