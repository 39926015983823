import { Flex, Box, Text } from "rebass/styled-components";
import Image from "next/image";
import useTranslation from "next-translate/useTranslation";
import theme from "../styles/theme";
import styles from "../styles/signUpStyle";
import { Input } from "@rebass/forms";
import { useState } from "react";
import Button from "./common/Button";
import Icon from "./common/Icon";

interface Props {
  isMobile: boolean;
  onClose: () => void;
}

function SignUp(props: Props) {
  const { isMobile, onClose } = props;
  const { t } = useTranslation("common");
  const [emailValue, setEmailValue] = useState("");

  const renderImg = () => {
    return (
      <Box sx={styles.imgBox}>
        <Image
          src="/blog/images/sign_up.webp"
          alt="signUpImage"
          loading="lazy"
          objectFit="cover"
          layout="fill"
        />
      </Box>
    );
  };

  const renderInput = () => {
    return (
      <Input
        id="sigUpEmail"
        name="signUpEmail"
        value={emailValue}
        placeholder={"Email"}
        required
        minLength={3}
        maxLength={50}
        onChange={(e) => setEmailValue(e.target.value)}
        sx={theme.input}
      />
    );
  };

  return (
    <Flex width="100%" sx={{ ...theme.colToRow, gap: "8px" }}>
      {!isMobile && renderImg()}

      <Flex sx={{ flexDirection: "column" }}>
        <Flex sx={styles.closeIcon} onClick={onClose}>
          <Box sx={{ cursor: "pointer" }}>
            <Icon name="close" color={"labradorBlack"} fontSize={2} />
          </Box>
        </Flex>

        <Flex sx={{ flexDirection: "column", gap: "24px", ...styles.infoFlex }}>
          <Text as="h2" sx={styles.title}>
            {t("signUp")}
          </Text>
          <Text as="h3" sx={theme.title}>
            {t("subscribeToNewsletter")}
          </Text>
          {renderInput()}
          <Button variant="primary" sx={{ p: "12px" }}>
            {t("signUpBtn")}
          </Button>
        </Flex>
      </Flex>

      {isMobile && renderImg()}
    </Flex>
  );
}

export default SignUp;
